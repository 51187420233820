<template>
  <div class="minha-conta" :style="seguros.length == 0 ? 'height:100vh' : ''">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>
          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Retenção</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <b-card class="mb-5 my-card">
        <div class="row my-3 mx-1">
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Telefone
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="telefone"
              />
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  CPF
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                v-mask="'###.###.###-##'"
                v-model="cpf"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  Email
                </span>
              </div>
              <input type="text" class="form-control" v-model="email" />
            </div>
          </div>

          <div class="col-md-4 ">
            <button
              type="submit"
              class="btn btn-success btn-block "
              @click.prevent="buscarSegurados()"
              :disabled="loading"
            >
              <span v-if="!loading">Buscar seguro</span>
              <span v-if="loading">Por favor aguarde...</span>
            </button>
          </div>
        </div>
      </b-card>

      <div class="row">
        <div class="col-lg-12 order-lg-2">
          <div class="text-center" v-if="seguros.length == 0 && !loading">
            <p>Nenhum seguro encontrado encontrado com a busca realizada.</p>
          </div>
          <div v-if="!loading">
            <b-card
              no-body
              class="mb-3 my-card"
              v-for="(seguro, index) in seguros"
              :key="'seguro' + index"
            >
              <div class="row " style="padding-top:20px;padding-left:20px">
                <div class="col-4">
                  <p><b>Nome:</b> {{ seguro.nome }}</p>
                  <p><b>Email:</b> {{ seguro.email }}</p>
                  <p><b>CPF: </b> {{ seguro.cpf }}</p>
                </div>

                <div class="col-4">
                  <p>
                    <b>Valor do plano:</b
                    >{{ formatacaoMoeda3(seguro.valorPlano) }}
                  </p>
                  <p>
                    <b>Valor da bike:</b>
                    {{ formatacaoMoeda3(seguro.valorBike) }}
                  </p>
                  <p><b>Ano:</b> {{ seguro.ano }}</p>
                </div>
                <div class="col-4">
                  <p><b>Marca:</b> {{ seguro.marca }}</p>
                  <p><b>Modelo:</b> {{ seguro.modelo }}</p>
                  <p><b>Ano:</b> {{ seguro.ano }}</p>
                </div>
                <div class="col-4">
                  <p v-if="seguro.dataIncioVigencia">
                    <b>Inicio vigência: </b>
                    {{
                      formatacaoData.formatarDataLocal(seguro.dataIncioVigencia)
                    }}
                  </p>
                  <p v-if="seguro.dataIncioVigencia">
                    <b>Fim vigência: </b
                    >{{
                      formatacaoData.formatarDataLocal(seguro.dataFimVigencia)
                    }}
                  </p>
                  <p><b>Assinatura: </b> {{ seguro.tipoAssinatura }}</p>
                </div>
              </div>
              <hr />
              <div class="text-left ml-3 mb-3">
                <div>
                  <b-button
                    @click.prevent="abrirModalSeguro(seguro)"
                    variant="primary"
                    >Renovar seguro</b-button
                  >
                </div>
              </div>
            </b-card>
          </div>

          <div v-else class="text-center">
            <lottie-player
              src="/anim/mountain_bike.json"
              background="transparent"
              speed="1.7"
              style="width: 72px; height: 72px; margin:0 auto;"
              autoplay
              loop
              class="mt-3"
            ></lottie-player>
            <div>Carregando, por favor aguarde...</div>
          </div>
        </div>
      </div>
    </div>

    <ModalRetencao :seguro="seguro" :showModal="showModal" @hide="hideModal" />
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import ModalRetencao from "../../components/Admin/ModalRetencao";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import helper from "../../helpers/helper";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import formatacaoData from "../../helpers/formatacaoData";

export default {
  components: {
    SideNav,
    ModalRetencao,
  },
  data() {
    return {
      formatacaoMoeda3,
      formatacaoData,
      statusSelecionado: null,
      // statusFaturaSelecionado: null,
      config,
      loading: false,
      seguros: [],
      // filtrados: [],
      email: null,
      telefone: null,
      cpf: null,
      idUsuarioAdm: undefined,
      helper,
      seguro: undefined,
      idSeguro: undefined,
      showModal: false,
      dataSeguro: null,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
  methods: {
    abrirModalSeguro(seguro) {
      this.seguro = seguro;

      this.showModal = true;
    },

    hideModal(status) {
      this.showModal = false;
      if (status.modificou) {
        this.seguros=[];
      }
    },

    async buscarSegurados() {
      if (!this.email && !this.nome && !this.telefone && !this.cpf) {
        Swal.fire({
          icon: "warning",
          text: "Informe ao menos um filtro!",
        });
        return;
      }

      this.loading = true;

      var data = {
        email: this.email,
        nome: this.nome,
        cpf: this.cpf,
      };

      try {
        var response = await axios.post(
          `${config.API_URLV2}/seguro/buscarSeguroCliente`,
          data
        );

        this.seguros = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar os segurados. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.my-card {
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  color: #252422;
  margin-bottom: 20px;
  position: relative;
  border: 0;
}

.minha-conta {
  font-family: "Montserrat";
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.14609593837535018) 0%,
    rgba(255, 255, 255, 0) 70%
  );
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}
</style>
